import { Stack } from 'react-bootstrap';
import './Story.css';
import { useTranslation } from "react-i18next";
import img_1 from '../img/sory/1.webp';
import img_2 from '../img/sory/2.webp';
import img_3 from '../img/sory/3.webp';
import img_4 from '../img/sory/4.webp';
import img_5 from '../img/sory/5.webp';
import img_6 from '../img/sory/6.webp';
import img_7 from '../img/sory/7.webp';
import img_8 from '../img/sory/8.webp';
import img_9 from '../img/sory/9.webp';
import img_10 from '../img/sory/10.webp';
import img_11 from '../img/sory/11.webp';
import img_12 from '../img/sory/12.webp';
import img_13 from '../img/sory/13.webp';
import { Plan } from '../components/Plan/Plan';


export const Story = () => {
    const { t } = useTranslation();
    const data = [
        {
            image: img_1,
            date: `${t("story.abril")} 20, 2023`,
            description: <>{t("story.i1")}</>
        },
        {
            image: img_2,
            date: `${t("story.mayo")} 06, 2023`,
            description: <>{t("story.i2")}</>
        },
        {
            image: img_3,
            date: `${t("story.julio")} 27, 2023`,
            description: <>{t("story.i3")}</>
        },
        {
            image: img_4,
            date: `${t("story.agosto")} 10, 2023`,
            description: <>{t("story.i4")}</>
        },
        {
            image: img_5,
            date: `${t("story.agosto")} 13, 2023`,
            description: <>{t("story.i5")}</>
        },
        {
            image: img_6,
            date: `${t("story.agosto")} 15, 2023`,
            description: <>{t("story.i6")}</>
        },
        {
            image: img_7,
            date: `${t("story.septiembre")} 30, 2023`,
            description: <>{t("story.i7")}</>
        },
        {
            image: img_8,
            date: `${t("story.octubre")} 06, 2023`,
            description: <>{t("story.i8")}</>
        },
        {
            image: img_9,
            date: `${t("story.noviembre")} 17, 2023`,
            description: <>{t("story.i9")}</>
        },
        {
            image: img_10,
            date: `${t("story.noviembre")} 18, 2023`,
            description: <>{t("story.i10")}</>
        },
        {
            image: img_11,
            date: `${t("story.noviembre")} 26, 2023`,
            description: <>{t("story.i11")}</>
        },
        {
            image: img_12,
            date: `${t("story.febrero")} 11, 2024`,
            description: <>{t("story.i12")}</>
        },
        {
            image: img_13,
            date: `${t("story.febrero")} 29, 2024`,
            description: <>{t("story.i13")}</>
        }
    ]
    return (
      <Stack>
        <Plan data={data}/>
      </Stack>
    );
  };