
import React from "react";
import "./Plan.css";

interface dataPlan {
    image: string,
    date: string,
    description: React.ReactElement
}

export const Plan = (props: {data: dataPlan[]}) => {
  

  return (
    <div className="planbox">
        <div className="planTimeline">
    {props.data.map((p, i) => (
        <div className={`${i % 2 === 0 ? 'planRowPar' : 'planRow'}`} key={`plan_${p.image}`}>
            <figure className="planImg">
                <picture>
                    <img alt={`image_${p.date}`} src={p.image} className="imgElementPlan"/>
                </picture>
            </figure>
            <div className="planText">
                <div className="planFecha">
                    {p.date}
                </div>
                <div>
                    {p.description}
                </div>
            </div>
            <div className="planEnd"></div>
        </div>
    ))}
        </div>
    </div>
  );
};