import { Stack } from 'react-bootstrap';
import './Home.css';
import { useTranslation } from "react-i18next";
import { Plan } from '../components/Plan/Plan';
import jardines from '../img/jardines.png';
import iglesia from '../img/iglesia.png';

export const Home = () => {

    const { t } = useTranslation();
    return (
      <Stack>        
        <div className="home-title">
          <a rel="noreferrer" href="https://invitacion.albertoymaria.com/" target="_blank">
            {t("perdiste")}
          </a>
        </div>
        <div className="timeline">

        </div>
        <div className="home">       
          <div className="home-fecha">
            <div className="home-content">
              <span>{t('date')}</span>
            </div>

          </div>
          <div className="home-lugar">
            <div className="home-content">
            Granada
            <br/>
            {t('country')}
            </div>

          </div>
        </div>
        <div className="home-mobile">
          <h4 className="home-mobile-wedding">{t('wedding')}</h4>
          <div className="home-mobile-date">{t('date')}</div>
        </div>
        <Plan
          data={[
            {
              image: iglesia,
              date: "18:30PM",
              description: <>
              <h2>{t("timeline.ceremony.title")}</h2>
              <a rel="noreferrer" href="https://maps.app.goo.gl/UpLGTgc7oVUzkEJu9" target="_blank">{t("timeline.ceremony.subtitle")}</a>.
              </>
            },
            {
              image: jardines,
              date: "20:30PM",
              description: <>
              <h2>{t("timeline.welcome.title")}</h2>
              <a rel="noreferrer" href="https://maps.app.goo.gl/LtJ3bguJMNyJatgNA" target="_blank">{t("timeline.welcome.subtitle")}</a>.<br/>{t("timeline.bus_go.description")}
              </>
            }
          ]}
        />
      </Stack>
    );
  };