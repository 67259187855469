export const IT = {
  title: "Applicazione multilingue",
  perdiste: "Vuoi vedere l'invito digitale?",
  label: "Selezionare un'altra lingua ",
  registro_descripcion: "Per confermare la partecipazione al matrimonio scrivete ad Alberto.",
  registro: "Confermare",
  que_hacer: "Fare?",
  tapeo: {
    name: "Dove mangiare/tapas",
    descripcion: "Qui sotto avete una mappa con i posti consigliati per bere qualcosa e le indicazioni stradali, qui a Granada è consuetudine servire una tapa (antipasto) con ogni bevanda."
  },
  ver: {
    name: "Cosa vedere",
    alhambra: "Questo imponente complesso, formato da splendidi palazzi, giardini e una fortezza in stile andaluso, fu costruito per ordine del re nasride Muhammad ben al-Ahmar nel 1238 e dopo la riconquista cristiana passò dall'essere sede dell'emiro e della corte dei Nasridi Regno fino a divenire a Castellana residenza reale. Biglietti sul sito ufficiale dell'Alhambra",
    albaicin: "È il quartiere più antico e affascinante da vedere a Granada.",
    paseo_tristes: "Situato tra i ponti arabi di Las Chirimías e Aljibillo.",
    sacromonte: "Conosciuto come il quartiere dei gitani e uno dei posti più affascinanti da vedere a Granada. Prenota in anticipo. Di seguito sono riportati due dei luoghi più famosi ed emblematici di Granada per goderti uno spettacolo di flamenco.",
    mirador: "Dove puoi ammirare la migliore vista dell'Alhambra con le montagne innevate della Sierra Nevada sullo sfondo.",
    catedral: "Orari della Cattedrale: dal lunedì al sabato dalle 10:00 alle 18:30 e la domenica dalle 15:00 alle 17:45. Orari Cappella Reale: dal lunedì al sabato dalle 10:15 alle 18:30 e la domenica dalle 11:00 alle 18:00.",
    alcaiceria: "Ex souk arabo, oggi, in questo labirinto di strade si trovano diversi negozi di souvenir e di artigianato dove è possibile acquistare le famose ceramiche Fajalauza o taracea o l'intarsio di legno.",
    corral: "L'unica alhóndiga nazarí (pub o mercato rionale).",
    hamman: "Fate un bagno rilassante e un massaggio."            
  },
  story: {
    title: "Nostra storia",
    abril: "aprile",
    mayo: "Maggio",
    julio: "Luglio",
    agosto: "Agosto",
    septiembre: "settembre",
    octubre: "ottobre",
    noviembre: "novembre",
    febrero: "Febbraio",
    i1: "Si comincia il 17 aprile",
    i2: "Ci siamo conosciuti sempre di più.",
    i3: "Festeggiamo i compleanni",
    i4: "Abbiamo trascorso una bella estate",
    i5: "E continuiamo a farlo",
    i6: "Viaggiamo in molti posti",
    i7: "E celebriamo molti eventi",
    i8: "Abbiamo visto molti amici",
    i9: "E realizziamo i sogni",
    i10: "Incredibile",
    i11: "Primo Natale",
    i12: "E la prima nevicata",
    i13: "E la magia è stata fatta",
  },
  planning: "Planificación",
  construccion: "In costruzione",
  about: "Su di me",
  home: "Casa",
  hotel: "Alloggio",
  the_day: "Il giorno",
  day_description: "Questa è la sequenza temporale per il giorno del matrimonio, è soggetta a modifiche di programma fino all'avvicinarsi della data",
  date: "5 Luglio, 2025",
  country: "Spagna",
  countdown: "Giorni Rimasti !",
  day_on: "Il giorno è già arrivato!",
  fotter_date: "5.7.2025",
  wedding: "Giorno del matrimonio",
  location: "Posizione",
  logistica: {
    bus_ida: "Autobus della chiesa - Giardini di Siddharta",
    bus_vuelta: "Ritorno in autobus agli hotel"
  },
  hotelpage: {
    code: "Codice",
    bus: "L'autobus passerà da questi hotel al ritorno dalla celebrazione",
    check_in: "Venerdì 4 luglio",
    check_out: "Domenica 6 luglio",
    rate: "Camera doppia",
    desayuno: "Colazione inclusa",
    abades_description: "Prenota attraverso il sito web. Il codice prevede uno sconto del 5%. Registrandoti al sito ottieni un ulteriore sconto del 10%. Fino a fine disponibilità o data massima 07/04/2025.",
    luna_description: "Prenotazione telefonica (è necessario lasciare carta di credito e data di scadenza a garanzia della prenotazione), cancellabile fino a 72 ore prima, prezzo parcheggio € 18,95/notte. Se prenoti due notti applicheremo uno sconto del 10%. Fino a 25 camere o data massima 05/04/2025",
    euro_real: "Sconto del 15% sulla migliore tariffa disponibile al momento della prenotazione e sarà valido per le notti dal 4 al 6 luglio 2025. Fino a 15 camere o data massima 4/06/2025",   
    euro_anton: "Sconto del 15% sulla migliore tariffa disponibile al momento della prenotazione e sarà valido per le notti dal 4 al 6 luglio 2025. Fino a 30 camere o data massima 4/06/2025"
  },
  timeline: {
    ceremony: {
      title: "Cerimonia",
      subtitle: "Iglesia Parroquial del Sagrario (Chiesa)",
      description: "Fu costruito nel 1704 sopra la sala di preghiera della moschea principale di Granada, che ospitò la Cattedrale di Granada nel 1526."
    },
    bus_go: {
      title: "Autobus",
      subtitle: "Sulla strada per la sede",
      description: "Ci saranno degli autobus che ci porteranno alla sede. Chiediamo puntualità."
    },
    welcome: {
      title: "Drink di benvenuto",
      subtitle: "Jardines de Siddharta(Giardini Siddhartha)",
      description: "Inizieremo con un aperitivo di benvenuto e alcuni antipasti per allietare la serata."
    },
    cena: {
      title: "Cena",
      subtitle: "Soggiorno al primo piano",
      description: "Iniziamo!"
    },
    barralibre: {
      title: "Open bar",
      subtitle: "Soggiorno al secondo piano",
      description: "È possibile accedervi tramite ascensore o scale. (È solo una pianta)"
    },
    autobus_vuelta_1: {
      title: "Autobus 1",
      subtitle: "Il primo autobus di ritorno è alle 3:00",
      description: "Questo autobus si fermerà all'Hotel Abades, in centro (Plz Batallas) e proseguirà lungo la strada Ronda fino all'Hotel Luna a Granada."
    },
    recena: {
      title: "Cena",
      subtitle: "Non andare !",
      description: "Ci sarà una cena e churros al cioccolato"
    },
    autobus_vuelta_2: {
      title: "Autobus 2",
      subtitle: "Secondo autobus per il ritorno alle 5:00",
      description: "Questo autobus si fermerà all'Hotel Abades, in centro (Plz Batallas) e proseguirà lungo la strada Ronda fino all'Hotel Luna a Granada."
    },
    fin: {
      title: "Fine?",
      subtitle: "Non lasciare che la festa finisca",
      description: "La gloria più grande non è non cadere mai, ma rialzarsi sempre"
    },
  }
};


export default IT;