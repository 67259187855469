import US from './us';
import ES from './es';
import IT from './it';

export const languages = {
    us: {
        translation: US
    },
    es: {
      translation: ES
    },
    it: {
      translation: IT
    }
};