import "./Timeline.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChampagneGlasses, faHamburger, faBusSimple, faCompactDisc, faChurch, faUtensilSpoon, faSun } from '@fortawesome/free-solid-svg-icons'
import salon from '../../img/salon.png';
import copa from '../../img/copa.png';
import iglesia from '../../img/iglesia.png';

export const Timeline = () => {
  const { t } = useTranslation();
  const data = [
    {
        "id": 1,
        "title": t("timeline.ceremony.title"),
        "subtitle": t("timeline.ceremony.subtitle"),
        "icon": faChurch,
        "para": t("timeline.ceremony.description"),
        "note": "18:30PM",
        "url": "https://maps.app.goo.gl/VpL2ydYzqVqfmgdw5",
        "img": iglesia
    },
    {
        "id": 2,
        "title": t("timeline.bus_go.title"),
        "subtitle": t("timeline.bus_go.subtitle"),
        "icon": faBusSimple,
        "para": t("timeline.bus_go.description"),
        "note": "20:00PM",
        "url": "https://maps.app.goo.gl/2iVqrCt8zDpam1d99"
    },
    {
      "id": 3,
      "title": t("timeline.welcome.title"),
      "subtitle": t("timeline.welcome.subtitle"),
      "icon": faChampagneGlasses,
      "para": t("timeline.welcome.description"),
      "note": "20:30PM",
      "url": "https://maps.app.goo.gl/XMjSb8c5R8Fp72yHA",
      "img": copa
    },
    {
      "id": 4,
      "title": t("timeline.cena.title"),
      "subtitle": t("timeline.cena.subtitle"),
      "icon": faUtensilSpoon,
      "para": t("timeline.cena.description"),
      "note": "~22:00PM",
      "img": salon
    },
    {
      "id": 5,
      "title": t("timeline.barralibre.title"),
      "subtitle": t("timeline.barralibre.subtitle"),
      "icon": faCompactDisc,
      "para": t("timeline.barralibre.description"),
      "note": "~00:00PM"
    },
    {
      "id": 6,
      "title": t("timeline.autobus_vuelta_1.title"),
      "subtitle": t("timeline.autobus_vuelta_1.subtitle"),
      "icon": faBusSimple,
      "para": t("timeline.autobus_vuelta_1.description"),
      "note": "~03:00AM"
    },
    {
      "id": 7,
      "title": t("timeline.recena.title"),
      "subtitle": t("timeline.recena.subtitle"),
      "icon": faHamburger,
      "para": t("timeline.recena.description"),
      "note": "~03:00AM"
    },
    {
        "id": 8,
        "title": t("timeline.autobus_vuelta_2.title"),
        "subtitle": t("timeline.autobus_vuelta_2.subtitle"),
        "icon": faBusSimple,
        "para": t("timeline.autobus_vuelta_2.description"),
        "note": "~05:00AM"
    },
    {
      "id": 9,
      "title": t("timeline.fin.title"),
      "subtitle": t("timeline.fin.subtitle"),
      "icon": faSun,
      "para": t("timeline.fin.description"),
      "note": "~06:00AM"
  },

    
]
  const content = data.map((item, index) => <div key={item.id} data-testid="timeline-item">

  {item.id % 2 !== 0 &&
      <div className="column-left ">
          <div className="card col-card-left">
              <div className="col-card-title col-card-title-left">{item.title}</div>              
              <div className="col-card-subtitle col-card-subtitle-left">{item.subtitle}</div>
                {item.img && (<img alt={`image_${item.id}`} className="col-img" src={item.img}/>)}
                <div className="col-card-para">
                    {item.para}
                </div>
              {item.url && (<a rel="noreferrer" className="numberCircle" href={item.url} target="_blank">{t("location")}</a>)}
          </div>
      </div>
  }
  {item.id % 2 !== 0 &&
      <div className="column-right" >
          <div className="col-note col-note-right">
              <p>{item.note}</p>
          </div>
      </div>
  }

  <div className="column-center" >
      <div className="line"></div>
      <div className="circle">
          <FontAwesomeIcon className="icon" icon={item.icon} />
      </div>
  </div>

  {item.id % 2 === 0 &&
      <div className="column-right" >
          <div className="card col-card-right">
              <div className="col-card-title col-card-title-right">{item.title}</div>
              <div className="col-card-subtitle col-card-subtitle-right">{item.subtitle}</div>
              {item.img && (<img alt={`image_${item.id}`} className="col-img" src={item.img}/>)}
              <div className="col-card-para">
                  {item.para}
              </div>
              {item.url && (<a rel="noreferrer" className="numberCircle" href={item.url} target="_blank">{t("location")}</a>)}                         
          </div>
      </div>
  }
  {item.id % 2 === 0 &&
      <div className="column-left" >
          <div className="col-note col-note-left">
              <p>{item.note}</p>
          </div>
      </div>
  }
  <div className="clearfix"></div>
</div>
);

  return (
    <div className="timeline">
       {content}
    </div>
  );
};