import { NavLink } from "react-router-dom";
import "./Menu.css";
import { useTranslation } from "react-i18next";
import FlagIcon from './FlagIcon';
import { useEffect, useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export const Menu = () => {
  const { i18n, t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const [active, setActive] = useState("home");
  const [countries] = useState([
      { label: "Spanish", code: "es" },
      { label: "English", code: "us" },
      { label: "Italian", code: "it" },    
  ]);
  const menuLinks = [
    {
      name: t("home"),
      key: "home",
      url: "/"
    },
    {
      name: t("story.title"),
      key: "story",
      url: "/story"
    },
    {
      name: t("the_day"),
      key: "the_day",
      url: "/day"
    },
    {
      name: t("hotel"),
      key: "hotel",
      url: "/hotel"
    }, 
    {
      name: t("planning"),
      key: "planning",
      url: "/planning"
    },  
    {
      name: t("que_hacer"),
      key: "what",
      url: "/what"
    },    
    {
      name: t("registro"),
      key: "registro",
      url: "/registry"
    }        
       
  ]
  const getActive = (path: string) => {
    return `link ${active === path ? "active" : "no-active"}`
  }
  const onChangeLang = (lang_code: string) => {
    i18n.changeLanguage(lang_code);
    setToggleContents(<FlagIcon code={lang_code}/>);
  };

  const [toggleContents, setToggleContents] = useState(<FlagIcon code={i18n.language}/>);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const lang = params.get('lang');
    if (lang === 'es' || lang === 'us' || lang === 'it') {
      setToggleContents(<FlagIcon code={lang}/>)
    } else {
      setToggleContents(<FlagIcon code={i18n.language}/>)
    }
  },[i18n])

  return (
    <Navbar expanded={expand} expand="lg" className="bg-body-tertiary menu-bs">
      <div className="menu-container">
        <Navbar.Toggle className="nav-toggle" onClick={() => setExpand(!expand)}aria-controls="basic-navbar-nav" />
        {!expand && (<div className="nv-title">{menuLinks.filter(m => m.key === active)[0].name}</div>)}        
        <Navbar.Collapse id="basic-navbar-nav" className="bs-links">
          <Nav className="me-auto">
              {menuLinks.map(item => (
                <NavLink key={`link_to_${item.name}`} className={getActive(item.key)} onClick={() => {setActive(item.key); setExpand(!expand)}} to={item.url}>{item.name}</NavLink>
               ))}            
            <div className="hidden-mobile">   
            <NavDropdown title={toggleContents} id="basic-nav-dropdown">
            {countries.filter(m => m.code!==i18n.language).map(({ code, label }) => ( 
                <NavDropdown.Item key={`lang_code_${code}`} className="flag_down" onClick={() => onChangeLang(code)}><FlagIcon code={code}/><span style={{padding: "15px"}}>{label}</span></NavDropdown.Item>
              ))}               
            </NavDropdown>
            </div>
            <div className="show-mobile">   
            {countries.filter(m => m.code!==i18n.language).map(({ code, label }) => ( 
                <span key={`lang_code_${code}`} className="flag_down" onClick={() => onChangeLang(code)}><FlagIcon code={code}/></span>
              ))}      
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};