import { Stack } from 'react-bootstrap';
import './Day.css';
import { useTranslation } from "react-i18next";
import { Timeline } from '../components/Timeline/Timeline';

export const Day = () => {
    const { t } = useTranslation();
    return (
      <Stack>
        <div className="day">
          <div className="day-description">
            {t("day_description")}
          </div>
          <Timeline/>
        </div>
      </Stack>
    );
  };