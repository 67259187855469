export const ES = {
    about: "Sobre nosotros",
    perdiste: "¿Quiéres ver la invitación digital?",
    registro: "Confirmación",
    planning: "Logística",
    que_hacer: "¿Qué hacer?",
    tapeo: {
      name: "Donde comer/tapear",
      descripcion: "A continuación teneis un mapa con sitios recomendados para tomar algo y direcciones, aqui en Granada es costumbre servir una tapa (aperitivo) con cada consumición"
    },
    ver: {
      name: "Que ver",
      alhambra: "Este imponente complejo formado por preciosos palacios, jardines y una fortaleza de estilo andalusí, fue construido por orden del Rey Nazarí Muhammad ben al-Ahmar en 1238 y después de la reconquista cristiana pasó de alojar al emir y la corte del Reino nazarí, a convertirse en residencia real Castellana. Entradas en pagina oficial de la Alhambra",
      albaicin: "Es el barrio más antiguo y con más encanto que ver en Granada.",
      paseo_tristes: "Situado entre los puentes árabes de las Chirimías y del Aljibillo.",
      sacromonte: "Conocido como el barrio de los gitanos y uno de los lugares con más encanto que ver en Granada. Reservar con antelación, A continuación dos de los sitios más famosos y emblemáticos de Granada para disfrutar de un espectáculo flamenco.",
      mirador: "Donde se podran ver las mejores vistas de la Alhambra con las montañas nevadas de Sierra Nevada de fondo.",
      catedral: "Horario de la Catedral: de lunes a sábado de 10h a 18:30h y domingos de 15h a 17:45h. Horario de la Capilla Real: lunes a sábado de 10:15h a 18:30h y domingos de 11h a 18 h.",
      alcaiceria: "Antiguo zoco árabe ,en la actualidad, en este laberinto de calles se encuentran varias tiendas de souvenirs y artesanía donde puedes comprar la popular cerámica de Fajalauza o la incrustación de madera o taracea.",
      corral: "La única alhóndiga nazarí (casa pública o mercado de los vecinos).",
      hamman: "Darse un relajante baño y un masaje."            
    },
    story: {
      title: "Nuestra historia",
      abril: "Abril",
      mayo: "Mayo",
      julio: "Julio",
      agosto: "Agosto",
      septiembre: "Septiembre",
      octubre: "Octubre",
      noviembre: "Noviembre",
      febrero: "Febrero",
      i1: "Empezamos un 17 de abril",
      i2: "Nos fuimos conociendo mas y mas",
      i3: "Celebramos cumpleaños",
      i4: "Disfrutamos de un buen verano",
      i5: "Y seguimos haciendolo",
      i6: "Viajamos a muchos sitios",
      i7: "Y celebramos muchos acontecimientos",
      i8: "Vimos a muchos amigos",
      i9: "Y cumplimos sueños",
      i10: "Increibles",
      i11: "Primeras navidades",
      i12: "Y primeras nevadas",
      i13: "Y se hizo la magia",
    },
    registro_descripcion: "Pedimos que nos indiqueis la asistencia como muy tarde antes del 5 de Junio. Gracias",
    home: "Inicio",
    the_day: "El Día",
    hotel: "Alojamiento",
    day_description: "Este es el timeline del día de la boda, esta sujeto a cambios de horarios hasta que se acerque más la fecha",
    date: "5 Julio, 2025",
    country: "España",
    countdown: "Dias que faltan !",
    day_on: "Ya ha llegado el día",
    fotter_date: "5.7.2025",
    wedding: "Dia de la boda",
    location: "Localizacion",
    logistica: {
      bus_ida: "Autobus Iglesia - jardines Siddharta",
      bus_vuelta: "Autobus vuelta hoteles"
    },
    hotelpage: {
      code: "Código",
      bus: "El autobus pasara por estos hoteles de vuelta de la celebración",
      check_in: "Viernes 4 de julio",
      check_out: "Domingo 6 de julio",
      rate: "Habitacion doble",
      desayuno: "Desayuno incluido",
      abades_description: "Reserva a traves de la web. El código aporta un 5% de descuento. Registrandose en la web se obtiene un 10% adicional de descuento. Hasta fin de disponibilidad o fecha máxima 04/07/2025.",
      luna_description: "Reserva por telefono (Hay que dejar tarjeta de credito y fecha de caducidad como garantía de la reserva), cancelable hasta 72 horas antes, precio parking 18,95€/noche. En caso de reservar dos noches , aplicaríamos un 10% de descuento. Hasta 25 habitaciones o fecha máxima  04/05/2025",
      euro_real: "Descuento del 15% sobre la mejor tarifa disponible en el momento de efectuar la reserva y será válido para las noches del 04 al 06 de julio de 2025. Hasta 15 habitaciones o fecha máxima 04/06/25",   
      euro_anton: "Descuento del 15% sobre la mejor tarifa disponible en el momento de efectuar la reserva y será válido para las noches del 04 al 06 de julio de 2025. Hasta 30 habitaciones o fecha máxima 04/06/25"
    },
    timeline: {
      ceremony: {
        title: "Ceremonia",
        subtitle: "Iglesia Parroquial del Sagrario",
        description: "Fue construida en 1704 sobre la sala de oraciones de la mezquita mayor de Granada, que albergaba en 1526 la Catedral granadina"
      },
      bus_go: {
        title: "Autobús",
        subtitle: "Camino al lugar de celebración",
        description: "Habrá autobuses que nos llevaran hasta el lugar de la celebración. Pedimos puntualidad."
      },
      welcome: {
        title: "Copa de bienvenida",
        subtitle: "Jardines de Siddharta",
        description: "Comenzaremos con una copa de bienvenida y unos entrantes para amenizar la noche."
      },
      cena: {
        title: "Cena",
        subtitle: "Salón de la primera planta",
        description: "¡ Empezamos !"
      },
      barralibre: {
        title: "Barra Libre",
        subtitle: "Salón en la segunda planta",
        description: "Podreís acceder por el ascensor o las escaleras. (Es solo una planta)"
      },
      autobus_vuelta_1: {
        title: "Autobús 1",
        subtitle: "Primer autobus de vuelta a las 3:00",
        description: "Este autobús parara en el hotel abades, en el centro (Plz batallas) y continuara por camino de ronda hasta el hotel luna de granada"
      },
      recena: {
        title: "Recena",
        subtitle: "¡ No os vayais !",
        description: "Habrá una recena y churros con chocolate"
      },
      autobus_vuelta_2: {
        title: "Autobús 2",
        subtitle: "Segundo autobus de vuelta a las 5:00",
        description: "Este autobús parara en el hotel abades, en el centro (Plz batallas) y continuara por camino de ronda hasta el hotel luna de granada"
      },
      fin: {
        title: "¿Final?",
        subtitle: "Que no pare la fiesta",
        description: "La mayor gloria no es no caer nunca, sino levantarse siempre"
      },
    }
  };

export default ES;