import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {languages} from "./constants/languages";

i18n.use(initReactI18next).init({
    lng: "es",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    resources: languages
  });

export default i18n;