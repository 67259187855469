import { Stack, Tabs, Tab } from 'react-bootstrap';
import './ToDo.css';
import { useTranslation } from "react-i18next";
import alhambra from '../img/ver/alhambra.jpeg';
import albaicin from '../img/ver/albaicin.jpeg';
import paseo_tristes from '../img/ver/paseo_tristes.jpeg';
import sacromonte from '../img/ver/sacromonte.jpeg';
import mirador from '../img/ver/mirador.jpeg';
import catedral from '../img/ver/catedral.jpeg';
import alcaiceria from '../img/ver/alcaiceria.jpeg';
import corral from '../img/ver/corral.jpeg';
import hamman from '../img/ver/hamman.jpeg';

export const ToDo = () => {

    const { t } = useTranslation();

    const tapeo = [
        {
            nombre: "Restaurante LOS MANUELES",
            direccion: "Calle Reyes Católicos, 61 (junto a la Plaza Nueva).",
            link: "https://maps.app.goo.gl/jE6newvGf6soCKNx7"
        },
        {
            nombre: "Bar LOS DIAMANTES",
            direccion: "Calle Navas, 28 , Calle Virgen del Rosario, 12.",
            link: "https://maps.app.goo.gl/R88tQJ4zW3VMudA7A"
        },
        {
            nombre: "Bodegas CASTAÑEDA",
            direccion: "Calle Almireceros, 1- 3",
            link: "https://maps.app.goo.gl/ZsB6qv4MvWvPJynr7"
        },
        {
            nombre: "Taberna LA TANA",
            direccion: "Placeta del Agua, 3",
            link: "https://maps.app.goo.gl/HccuMM9Cv698T4FG7"
        },
        {
            nombre: "Bar AVILA",
            direccion: "Calle Verónica de la Virgen, 16",
            link: "https://maps.app.goo.gl/ZeozpqoNzSs9DKuP9"
        },
        {
            nombre: "Casa TORCUATO",
            direccion: "Calle Pagés, 31",
            link: "https://maps.app.goo.gl/xN8YgoEAv2a7GyWT8"
        },
        {
            nombre: "La ESQUINITA DE JAVI",
            direccion: "Plaza de Mariana Pineda, 1",
            link: "https://maps.app.goo.gl/Uq8PLStp6LXG8QvKA"
        },
        {
            nombre: "Bar restaurante EL KIKI",
            direccion: "alle Palencia 25 .",
            link: "https://maps.app.goo.gl/VSXDyFa3khaMyMKn8"
        },
        {
            nombre: "Restaurante Pescaito El Ancla",
            direccion: "C. Pintor Manuel Maldonado, 50",
            link: "https://maps.app.goo.gl/HMNAZ4BzSZ55SkR96"            
        }
    ]

    const que_ver = [
        {
            name: "Alhambra",
            img: alhambra,
            descripcion: t("ver.alhambra")
        },
        {
            name: "Albaicin",
            img: albaicin,
            descripcion: t("ver.albaicin")
        },
        {
            name: "Paseo de los tristes",
            img: paseo_tristes,
            descripcion: t("ver.paseo_tristes"),
            link: <a target="_blank" rel="noreferrer" href="https://www.civitatis.com/es/granada/free-tour-granada/?aid=1051">Free Tour</a>
        },
        {
            name: "Barrio del Sacromonte",
            img: sacromonte,
            descripcion: t("ver.sacromonte"),
            link: <a target="_blank" rel="noreferrer" href="https://www.civitatis.com/es/granada/flamenco-cena-cuevas-tarantos/?aid=1051"> Cuevas de los Tarantos</a>,
            link2: <a target="_blank" rel="noreferrer" href="https://www.civitatis.com/es/granada/show-flamenco-amayas/?aid=1051"> Cueva los Mayas</a>
        },
        {
            name: "Mirador de San Nicolas",
            img: mirador,
            descripcion: t("ver.mirador")
        },
        {
            name: "Catedral de Granada",
            img: catedral,
            descripcion: t("ver.catedral")
        },
        {
            name: "Alcaiceria",
            img: alcaiceria,
            descripcion: t("ver.alcaiceria")
        },
        {
            name: "Corral del carbón",
            img: corral,
            descripcion: t("ver.corral")
        },
        {
            name: "Hamman Al Andalus",
            img: hamman,
            descripcion: t("ver.hamman")
        }     
    ]
    return (
      <Stack>    
            <Tabs
                defaultActiveKey="tapas"
                className="mb-3 tabs_to_do"
                transition={false}
                justify
            >
                <Tab eventKey="tapas" className="tab_to_do" title={`${t("tapeo.name")}`}>
                    {t("tapeo.descripcion")}
                       <iframe title="tapas_map" className="mapa_tapeo" src="https://www.google.com/maps/d/u/1/embed?mid=1xeJSxKbAUMkMySgNiqSRSOfqTsG5g1k&ehbc=2E312F" width="640" height="480"></iframe>                       
                       <ul className="tapeo_lista">
                        {tapeo.map(tap => (
                            <li key={`tapeo_${tap.nombre}`}>
                                <span >{tap.nombre}</span>
                                <a target="_blank" rel="noreferrer"  href={`${tap.link}`}>{tap.direccion}</a>
                            </li>
                        ))}
                       </ul>
                </Tab>
                <Tab eventKey="cultura" title={`${t("ver.name")}`}>
                    {que_ver.map(ver => (
                        <div key={`ver_${ver.name}`} className="ver_granada">
                            <img alt={`img_${ver.name}`} className="image_ver" src={ver.img} />
                            <div className="ver_name"> {ver.name} </div>
                            <div className="ver_description"> {ver.descripcion}</div>
                            {ver.link && (
                                <div className="ver_links">
                                    {ver.link}
                                    {ver.link2 && (<><br/><br/>{ver.link2}</>)}
                                </div>
                            )}
                        </div>
                    ))}
                </Tab>
            </Tabs>
       </Stack>
    );
  };