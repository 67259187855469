import { Container, Stack, Row, Col } from 'react-bootstrap';
import './Logistica.css';
import { useTranslation } from "react-i18next";

export const Logistica = () => {

    const { t } = useTranslation();
    return (
      <Stack className="logistica">        
       {t("planning")}
       <Container className="legenda">
        <Row>
            <Col xs><div className='cuadrado cuadrado_azul'></div>{t("logistica.bus_ida")}</Col>
            </Row><Row>
            <Col xs><div className='cuadrado cuadrado_rojo'></div>{t("logistica.bus_vuelta")}</Col>
        </Row>
       </Container>          


       <iframe title="mapa_boda" src="https://www.google.com/maps/d/u/1/embed?mid=1y-ypl6yNrKcdzs76EcSYdszcfCCtQeM&ehbc=2E312F" width="100%" height="480"></iframe>
      </Stack>
    );
  };