import { Stack } from 'react-bootstrap';
import './Registry.css';
import { useTranslation } from "react-i18next";

export const Registry = () => {
    const { i18n, t } = useTranslation();
    return (
      <Stack>
        <div className="registry">
          <div className="registry-description">
            {t("registro_descripcion")}
          </div>
          {i18n.language === 'es' && (
            <>
            <a rel="noreferrer" className="registry-link-mobile" href="https://forms.gle/Uft4edmhDAocRFvQ7" target="_blank"> Puede rellenar el formulario en este link</a>             
            <a rel="noreferrer" className="registry-link" href="https://forms.gle/Uft4edmhDAocRFvQ7" target="_blank"> Puede rellenar el formulario en este link o en la web</a>             
            <iframe className="registry-form" title="Registry form" src="https://docs.google.com/forms/d/e/1FAIpQLSdr1WllR6xPIBS6rpUBT0CHcbzHyToo5HuJiXcCM04buRJd3g/viewform?embedded=true" width="100%" height="100%">Cargando…</iframe>
            
            </>
          )}
        </div>
      </Stack>
    );
  };