export const US = {
    about: "About",
    perdiste: "Do you want to see the digital invitation?",
    construccion: "In progress...",
    registro: "Registry",
    que_hacer: "To do?",
    tapeo: {
      name: "Where to eat/tapas",
      descripcion: "Below you have a map with recommended places to have a drink and directions, here in Granada it is customary to serve a tapa (appetizer) with each drink."
    },
    ver: {
      name: "What to see",
      alhambra: "This imposing complex, made up of beautiful palaces, gardens and an Andalusian-style fortress, was built by order of the Nasrid King Muhammad ben al-Ahmar in 1238 and after the Christian reconquest it went from housing the emir and the court of the Nasrid Kingdom to becoming in Castellana royal residence. Tickets on the official website of the Alhambra",
      albaicin: "It is the oldest and most charming neighborhood to see in Granada.",
      paseo_tristes: "Located between the Arab bridges of Las Chirimías and Aljibillo",
      sacromonte: "Known as the gypsy neighborhood and one of the most charming places to see in Granada. Book in advance. Below are two of the most famous and emblematic places in Granada to enjoy a flamenco show.",
      mirador: "Where you can see the best views of the Alhambra with the snow-capped Sierra Nevada mountains in the background.",      
      catedral: "Cathedral hours: Monday to Saturday from 10 a.m. to 6:30 p.m. and Sundays from 3 p.m. to 5:45 p.m. Royal Chapel hours: Monday to Saturday from 10:15 a.m. to 6:30 p.m. and Sundays from 11 a.m. to 6 p.m.",
      alcaiceria: "Former Arab souk, today, in this labyrinth of streets there are several souvenir and craft shops where you can buy the popular Fajalauza ceramics or wood inlay or taracea.",
      corral: "The only Nasrid alhóndiga (public house or neighborhood market).",
      hamman: "Take a relaxing bath and a massage."            
    },
    story: {
      title: "Our story",
      abril: "APRIL",
      mayo: "MAY",
      julio: "JULY",
      agosto: "AUGUST",
      septiembre: "SEPTEMBER",
      octubre: "OCTOBER",
      noviembre: "NOVEMBER",
      febrero: "February",
      i1: "We start on April 17",
      i2: "We got to know each other more and more.",
      i3: "We celebrate birthdays",
      i4: "We enjoyed a good summer",
      i5: "And we keep doing it",
      i6: "We travel to many places",
      i7: "And we celebrate many events",
      i8: "We saw many friends",
      i9: "And we fulfill dreams",
      i10: "Incredible",
      i11: "First christmas",
      i12: "And first snowfall",
      i13: "And magic",
    },
    registro_descripcion: "To confirm attendance at the wedding, write to Alberto.",
    planning: "Planning",
    home: "Home",          
    the_day: "The Day",
    hotel: "Accommodation",
    day_description: "This is the timeline for the wedding day, it is subject to schedule changes until the date gets closer",
    date: "July 5, 2025",
    country: "Spain",
    countdown: "Days To Go !",
    day_on: "Let's go !",
    fotter_date: "7.5.2025",
    wedding: "Wedding Day",
    location: "Location",
    logistica: {
      bus_ida: "Bus from Church - Siddharta Gardens",
      bus_vuelta: "Bus back to hotels"
    },
    hotelpage: {
      code: "Code",
      bus: "The bus will pass by these hotels on the way back from the celebration",
      check_in: "Friday, July 4",
      check_out: "Sunday, July 6",
      rate: "Double room",
      desayuno: "Breakfast included",
      abades_description: "Reserve through the website. The code provides a 5% discount. By registering on the website you get an additional 10% discount. Until the end of availability or maximum date 07/04/2025.",
      luna_description: "Reservation by phone (You must leave credit card and expiration date as a guarantee of the reservation), cancelable up to 72 hours before, parking price €18.95/night. If you book two nights, we would apply a 10% discount. Up to 25 rooms or maximum date 05/04/2025",
      euro_real: "15% discount on the best rate available at the time of booking and will be valid for the nights from July 4 to 6, 2025. Up to 15 rooms or maximum date 06/04/25",   
      euro_anton: "15% discount on the best rate available at the time of booking and will be valid for the nights from July 4 to 6, 2025. Up to 30 rooms or maximum date 06/04/25"
    },
    timeline: {
      ceremony: {
        title: "Ceremony",
        subtitle: "Iglesia Parroquial del Sagrario (Church)",
        description: "It was built in 1704 over the prayer room of the main mosque of Granada, which housed the Granada Cathedral in 1526."
      },
      bus_go: {
        title: "Bus",
        subtitle: "On the way to the venue",
        description: "There will be buses that will take us to the venue. We ask for punctuality."
      },
      welcome: {
        title: "Welcome drink",
        subtitle: "Jardines de Siddharta (Siddhartha Gardens)",
        description: "We will start with a welcome drink and some starters to liven up the night."
      },
      cena: {
        title: "Dinner",
        subtitle: "First floor living room",
        description: " Start !"
      },
      barralibre: {
        title: "Open bar",
        subtitle: "Second floor living room",
        description: "You can access it by elevator or stairs. (It's just a plant)"
      },
      autobus_vuelta_1: {
        title: "Bus 1",
        subtitle: "First bus back at 3:00",
        description: "This bus will stop at the Abades Hotel, in the center (Plz Batallas) and continue along the Ronda Road to the Luna of Granada Hotel."
      },
      recena: {
        title: "Dinner 2",
        subtitle: "Don't go !",
        description: "There will be a small dinner and churros with chocolate"
      },
      autobus_vuelta_2: {
        title: "Bus 2",
        subtitle: "Second bus back at 5:00",
        description: "This bus will stop at the Abades Hotel, in the center (Plz Batallas) and continue along the Ronda Road to the Luna of Granada Hotel."
      },
      fin: {
        title: "End?",
        subtitle: "Don't let the party stop",
        description: "The greatest glory is not to never fall, but to always rise"
      }
    }
};

export default US;